<template>
    <div class="hero min-h-screen bg-base-200">
        
        <div class="hero-content flex-col lg:flex-row-reverse">
            
          <div class="text-center lg:text-left">
            
            <h1 class="text-5xl font-bold">Use our API now!</h1>
            <p class="py-6">Here you can perform the transactions you are curious about! We will give you the necessary information after you enter the token we provide!</p>
          </div>
          <div class="card shrink-0 transition-all w-full max-w-sm shadow-2xl bg-base-100">
            <div class="card-body transition-all">
              <div class="form-control">
                <label class="label">
                  <span class="label-text">Token</span>
                </label>
                <input type="password" placeholder="***" class="input input-bordered" @input="showerror = false;" v-model="token" required />
              </div>
              <div class="form-control mt-6">
                <button class="btn btn-primary flex gap-2 relative" @click="CheckToken()">
                  <p class="w-full text-center">Check Token</p>
                  <fwb-spinner size="5" class="absolute right-2" v-if="loading" />
                </button>
              </div>
            </div>
            <fwb-toast divide type="warning" v-if="showerror">
              {{errormessage}}
            </fwb-toast>
          </div>
  
        </div>
      </div>
</template>

<script setup>
import { FwbSpinner, FwbToast  } from 'flowbite-vue'

</script>

<script>
import axios from 'axios'

export default {
  name: 'App',
  data() {
    return {
      token: '',
      loading : false,
      showerror : false,
      errormessage : '',
    }
  },
  mounted() {
    if (localStorage.getItem('usercompanyid')) {
      this.$router.push({ path: `/docinfo/${localStorage.getItem('usercompanyid')}` });
    }
  },
  methods: {
    CheckToken() {
      if (!this.token) {
        return
      }
      this.loading = true
      var _this = this
      setTimeout(function() {
        this.showerror = true
        axios.post('https://x7a5as4d1ggg1.store/Check-MID-Token', {
                    token : _this.token
                })
                .then(response => {
                    if (response.data.status == false) {
                      
                      _this.loading = false
                      _this.errormessage = response.data.error
                      _this.showerror = true
                    } else {
                    
                        _this.loading = false
                        const id = response.data.tokenData.company
                       // const id = "hsnnn"
                       localStorage.setItem('usercompanyid', id)
                       localStorage.setItem('token', _this.token)
                       _this.$router.push({ path: `/docinfo/${id}` });
                    }
                })
        }, 2000); // 2 saniye gecikme ekleniyor
      }
    },
  components: {

  }
}
</script>