<template>
<div class="w-full h-full overflow-x-hidden">
  <button data-drawer-target="cta-button-sidebar" data-drawer-toggle="cta-button-sidebar" aria-controls="cta-button-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
    <span class="sr-only">Open sidebar</span>
    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
    </svg>
 </button>
 
 <aside id="cta-button-sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
    <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
       <ul class="space-y-2 font-medium">
          <li>
             <a href="" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                   <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
                   <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
                </svg>
                <span class="ms-3">Dashboard</span>
             </a>
          </li>
          <li>
             <a href="" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                   <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">{{$route.params.id}}</span>
                <span class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">New</span>
             </a>
          </li>
          <!-- <li>
             <a href="" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                   <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z"/>
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Under Construction</span>
                <span class="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>
             </a>
          </li>
          <li>
             <a href="" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                   <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Under Construction</span>
             </a>
          </li>
          <li>
             <a href="" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                   <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Under Construction</span>
             </a>
          </li>
          <li>
             <a href="" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"/>
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Under Construction</span>
             </a>
          </li>
          <li>
             <a href="#" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                   <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                   <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                   <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Under Construction</span>
             </a>
          </li> -->
       </ul>
       <div id="dropdown-cta" class="p-4 mt-6 rounded-lg bg-blue-50 dark:bg-blue-900" role="alert">
          <div class="flex items-center mb-3">
             <span class="bg-orange-100 text-orange-800 text-sm font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900">Beta</span>
             
          </div>
          <p class="mb-3 text-sm text-blue-800 dark:text-blue-400">
            We are trying to prepare for you in the best way possible!
          </p>
          
       </div>
    </div>
 </aside>
 
 <div class="p-4 sm:ml-64">
    <div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
       <!-- <div class="grid grid-cols-1 gap-4 mb-4">
          <div class="flex items-center justify-center relative font-bold font-[Poppins] h-96 rounded bg-gray-50 dark:bg-gray-800">
            <div class="absolute">
              <fwb-spinner size="10"   />
            </div>
            <div class="absolute w-full h-full flex justify-end items-end" style="filter : blur(4px)">
              <div class=" text-center right-1 bottom-1 w-fit h-fit p-3">
                <p>Latest Syncranization</p>
                <p class="text-xs text-[#41B06E]">1 Hours Ago</p>
            </div>
            </div>

          </div>
          
       </div> -->
       <div class="flex items-center relative flex-col justify-center h-fit mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <h1 class=" m-4 top-0 left-0 font-bold text-2xl">Hosted Payment Page Deposit Integration</h1>
            <div class="w-full h-[110vh]  bottom-0 flex gap-2">
                <div class="w-fit h-fit p-2 m-4 text-[#FFF] rounded-xl text-lg font-[Montserrat] font-medium border max-w-[50vw] bg-[#1E1E1E]">
 
                  <div class="flex flex-col min-w-[35vw] h-fit bg-gray-900 text-gray-200 font-mono">
                    <!-- Top bar -->
                    <div class="flex items-center h-10 px-4 bg-gray-800">
                        <div class="h-3 w-3 mr-2 rounded-full bg-red-500"></div>
                        <div class="h-3 w-3 mr-2 rounded-full bg-yellow-500"></div>
                        <div class="h-3 w-3 rounded-full bg-green-500"></div>
                    </div>
                
                    <!-- CMD body -->
                    <div class="flex-1 p-4">
                        <div class="flex">
                            <div class="mr-2">&gt;</div>
                            <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type your token here" v-model="token">
                        </div>
                        <div class="flex mt-2">
                          <div class="mr-2">&gt;</div>
                          <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type redirect URL" v-model="redirect_uri">
                        </div>
                        <div class="flex mt-2">
                          <div class="mr-2">&gt;</div>
                          <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type the name of the user who will make the request here" v-model="user">
                        </div>
                        <div class="flex mt-2">
                          <div class="mr-2">&gt;</div>
                          <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type the UUID of the user who will make the request here" v-model="uuid">
                        </div>
                        <div class="mt-2">
                          <span class="text-green-500">&gt; Request Data:</span>
                          <div class="bg-gray-800 p-2 mt-1">
                              <p>    
                                <span class="text-green-500">curl</span> -X POST <span class="text-red-500">https://x7a5as4d1ggg1.store/deposit-control</span> \
                                <br>
                                -H "Content-Type: application/json" \
                                <br>
                                -d '{
                                  <br>
                                    &nbsp;"token": <span class="text-green-500 truncate">"{{token.substring(0, 40)}}..."</span>,
                                    <br>
                                    &nbsp;"redirect_uri": <span class="text-green-500">"{{redirect_uri}}"</span>,
                                    <br>
                                    &nbsp;"user": <span class="text-green-500">"{{user}}"</span>,
                                    <br>
                                    &nbsp;"uuid": <span class="text-green-500">"{{uuid}}"</span>
                                    <br>
                                }'
                                </p>
                          </div>
                      </div>
                        <div class="mt-2">
                            <span class="text-green-500">&gt; Output:</span>
                            <div class="bg-gray-800 p-2 mt-1">
                                <p>   
                                  {
                                    <br>
                                    &nbsp; token : <span style="filter: blur(10px)" class="select-none">'a1xas54dasdzxcvbdfg4sdf1sfd.asda4'</span>
                                    <br>
                                  }
                                  </p>
                            </div>
                        </div>
                        <div class="mt-2">
                          <span class="text-green-500">&gt; Then:</span>
                          <div class="bg-gray-800 p-2 mt-1">
                              <p class="flex flex-col gap-1">    
                                <span class="text-red-500">if (data?.token) {</span>
                               
                                  <span class="ml-[1vw] flex flex-col text-green-500">
                                    <span>const url = "https://x7a5as4d1ggg.com/deposit?token=" + data.token;</span>
                                    <span>window.location.href = url</span>

                                  </span>
                                  
                                  <span class="text-red-500">}</span>
                                </p>
                          </div>
                      </div>
                    </div>
                </div>
                </div>
                <div class="p-4 text-base font-medium font-[Montserrat] flex flex-col gap-2">
                  <p>Each company we provide our system to has its own token and (usually) all returning transactions are carried out through this token. As you can see from the screenshot below, there is nothing extra for the ‘Deposit’ that will be too burdensome and troublesome for you, we handle everything in the background! The data that will be returned to you after this fetch code is a token.
                    This token holds your information in our system. Unlike other systems, we aim to hide it from users and ensure your and our security!</p>
                    <button type="button" class="w-fit text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="TestDeposit()">
                      Test Deposit
                      <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                      </svg>
                      </button>
                  </div>

            </div>
       </div>
       <div class="flex items-center relative justify-center h-fit flex-col mb-4 rounded bg-gray-50 dark:bg-gray-800">
        <h1 class=" m-4 top-0 left-0 font-bold text-2xl">Hosted Payment Page Withdraw Integration </h1>
        <div class="w-full h-[110vh]  bottom-0 flex gap-2">
            <div class="w-fit h-fit p-2 m-4 text-[#FFF] rounded-xl text-lg font-[Montserrat] font-medium border max-w-[50vw] bg-[#1E1E1E]">

              <div class="flex flex-col min-w-[35vw] h-fit bg-gray-900 text-gray-200 font-mono">
                <!-- Top bar -->
                <div class="flex items-center h-10 px-4 bg-gray-800">
                    <div class="h-3 w-3 mr-2 rounded-full bg-red-500"></div>
                    <div class="h-3 w-3 mr-2 rounded-full bg-yellow-500"></div>
                    <div class="h-3 w-3 rounded-full bg-green-500"></div>
                </div>
            
                <!-- CMD body -->
                <div class="flex-1 p-4">
                    <div class="flex">
                        <div class="mr-2">&gt;</div>
                        <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type your token here" v-model="token">
                    </div>
                    <div class="flex mt-2">
                      <div class="mr-2">&gt;</div>
                      <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type redirect URL" v-model="redirect_uri">
                    </div>
                    <div class="flex mt-2">
                      <div class="mr-2">&gt;</div>
                      <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type the name of the user who will make the request here" v-model="user">
                    </div>
                    <div class="flex mt-2">
                      <div class="mr-2">&gt;</div>
                      <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type the UUID of the user who will make the request here" v-model="uuid">
                    </div>
                    <div class="mt-2">
                      <span class="text-green-500">&gt; Request Data:</span>
                      <div class="bg-gray-800 p-2 mt-1">
                          <p>    
                            <span class="text-green-500">curl</span> -X POST <span class="text-red-500">https://x7a5as4d1ggg1.store/withdraw-control</span> \
                            <br>
                            -H "Content-Type: application/json" \
                            <br>
                            -d '{
                              <br>
                                &nbsp;"token": <span class="text-green-500 truncate">"{{token.substring(0, 40)}}..."</span>,
                                <br>
                                &nbsp;"redirect_uri": <span class="text-green-500">"{{redirect_uri}}"</span>,
                                <br>
                                &nbsp;"user": <span class="text-green-500">"{{user}}"</span>,
                                <br>
                                &nbsp;"uuid": <span class="text-green-500">"{{uuid}}"</span>
                                <br>
                            }'
                            </p>
                      </div>
                  </div>
                    <div class="mt-2">
                        <span class="text-green-500">&gt; Output:</span>
                        <div class="bg-gray-800 p-2 mt-1">
                            <p>   
                              {
                                <br>
                                &nbsp; token : <span style="filter: blur(10px)" class="select-none">'a1xas54dasdzxcvbdfg4sdf1sfd.asda4'</span>
                                <br>
                              }
                              </p>
                        </div>
                    </div>
                    <div class="mt-2">
                      <span class="text-green-500">&gt; Then:</span>
                      <div class="bg-gray-800 p-2 mt-1">
                          <p class="flex flex-col gap-1">    
                            <span class="text-red-500">if (data?.token) {</span>
                           
                              <span class="ml-[1vw] flex flex-col text-green-500">
                                <span>const url = "https://x7a5as4d1ggg.com/withdraw?token=" + data.token;</span>
                                <span>window.location.href = url</span>

                              </span>
                              
                              <span class="text-red-500">}</span>
                            </p>
                      </div>
                  </div>
                </div>
            </div>
            </div>
            <div class="p-4 text-base font-medium font-[Montserrat] flex flex-col gap-2">
              <p>Each company we provide our system to has its own token and (usually) all returning transactions are carried out through this token. As you can see from the screenshot below, there is nothing extra for the ‘Withdraw’ that will be too burdensome and troublesome for you, we handle everything in the background! The data that will be returned to you after this fetch code is a token.
                This token holds your information in our system. Unlike other systems, we aim to hide it from users and ensure your and our security!</p>
                <button type="button" class="w-fit text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="TestWithdraw()">
                  Test Withdraw
                  <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
                  </button>
              </div>
            
        </div>
   </div>
   <div class="flex items-center flex-col relative justify-center h-[120vh] mb-4 rounded bg-gray-50 dark:bg-gray-800">
    <h1 class=" m-4 top-0 left-0 font-bold text-2xl">Get User's All Transactions </h1>
    <div class="w-full h-[110vh]  bottom-0 flex gap-2">
        <div class="w-fit h-fit p-2 m-4 text-[#FFF] rounded-xl text-lg font-[Montserrat] font-medium border max-w-[50vw] bg-[#1E1E1E]">

          <div class="flex flex-col min-w-[35vw] h-fit bg-gray-900 text-gray-200 font-mono">
            <!-- Top bar -->
            <div class="flex items-center h-10 px-4 bg-gray-800">
                <div class="h-3 w-3 mr-2 rounded-full bg-red-500"></div>
                <div class="h-3 w-3 mr-2 rounded-full bg-yellow-500"></div>
                <div class="h-3 w-3 rounded-full bg-green-500"></div>
            </div>
        
            <!-- CMD body -->
            <div class="flex-1 p-4">
                <div class="flex">
                    <div class="mr-2">&gt;</div>
                    <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type your token here" v-model="token">
                </div>
                <div class="flex mt-2">
                  <div class="mr-2">&gt;</div>
                  <input type="text" class="flex-1 bg-gray-800 focus:outline-none" placeholder="Type the UUID of the user who will make the request here" v-model="uuid">
                </div>
                <div class="mt-2">
                  <span class="text-green-500">&gt; Request Data:</span>
                  <div class="bg-gray-800 p-2 mt-1">
                      <p>    
                        <span class="text-green-500">curl</span> -X POST <span class="text-red-500">https://x7a5as4d1ggg1.store/get-all-transactions-from-uuid</span> \
                        <br>
                        -H "Content-Type: application/json" \
                        <br>
                        -d '{
                          <br>
                            &nbsp;"token": <span class="text-green-500 truncate">"{{token.substring(0, 40)}}..."</span>,
                            <br>
                            &nbsp;"uuid": <span class="text-green-500">"{{uuid}}"</span>
                            <br>
                        }'
                        </p>
                  </div>
              </div>
                <div class="mt-2">
                    <span class="text-green-500">&gt; Output:</span>
                    <div class="bg-gray-800 p-2 mt-1">
                        <p>   
                          {
                            <br>
                            transactions : [
            {
                "dateStr": "24.04.2024 05:37",
                "amount": 501,
                "status": "pending",
                "type": "deposit",
                "timestamp": 167234
            },
            {
                "dateStr": "24.04.2024 06:02",
                "amount": 500,
                "status": "resolved",
                "type": "deposit",
                "result": "approved" & "rejected",
                "timestamp": 167234
            }...
        ]
                            <br>
                          }
                          </p>
                    </div>
                </div>

            </div>
        </div>
        </div>
        <div class="p-4 text-base font-medium font-[Montserrat]">
          You can view all deposit and withdrawal transactions made by the user through our system.</div>
        
    </div>
</div>
       <div class="grid grid-cols-1 gap-4 mb-4">
          <div class="flex p-[4vh] flex-col items-center rounded bg-green-500  gap-4 h-[200vh] dark:bg-gray-800">
            <div class="w-full h-fit"></div>
              <div class="w-full h-fit ">
                <section class="bg-gray-50 dark:bg-gray-900 rounded-md">
                  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
                      <div class="flex flex-col justify-center">
                          <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Testing MID  <br>Host to Host <br><span class="text-red-500">Deposit</span></h1>
                          <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Here you can use our API to create your own payment method and test it.</p>

                      </div>
                      <div>
                          <div class="w-full lg:max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow-xl dark:bg-gray-800">
                              <h2 class="text-2xl font-bold text-gray-900 dark:text-white">
                                  Mospay Payment System
                              </h2>
                              <div class="mt-8 space-y-6" action="">
                                  <div v-if="!selectediban" >
                                      <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Name</label>
                                      <input type="text" v-model="testmidname" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Richard" required />
                                  </div>
                                  <div v-if="!selectediban" >
                                    <label for="surname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Surname</label>
                                    <input type="text" v-model="testmidsurname" name="surname" id="surname" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Scott" required />
                                </div>
                                  <div v-if="!selectediban" >
                                      <label for="amount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                                      <input type="number" v-model="testmidnumber" name="amount" id="amount" placeholder="100 TRY" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                  </div>
                                  <div v-if="!selectediban" >
                                    <label for="text2" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User - (Required for code integration) <span class="text-red-500">*</span></label>
                                    <input type="text" v-model="user" name="text2" id="text2" placeholder="hsnweix" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                </div>
                                <div v-if="!selectediban" >
                                  <label for="text3" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User UUID - (Required for code integration) <span class="text-red-500">*</span></label>
                                  <input type="text" v-model="uuid" name="text3" id="text3" placeholder="#hsn123456" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                              </div>
                                  <div v-if="!selectediban" >
                                    <label for="banklist" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bank</label>
                                    <select name="banklist" v-model="bank" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="cars">
                                      <option v-for="(value,key) in banklist"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :value="key" :key="key">{{value.name}}</option>
                                      
                                    </select>
                                  </div>
                                  <div v-if="selectediban" >
                                    <label for="holder" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Account Holder</label>
                                    <input type="holder" readonly name="holder" id="holder" :placeholder="selectediban.holder" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                                  </div>
                                  <div v-if="selectediban" >
                                    <label for="iban" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">IBAN</label>
                                    <input type="iban" readonly name="iban" id="iban" :placeholder="selectediban.iban" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                                  </div>

  
                                  <button  class="w-full px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" v-if="!selectediban" @click="CheckStep(1)">Next Step </button>
                                  <button  class="w-full px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" v-if="selectediban" @click="CheckStep(2)">I made the payment!</button>
                                  <div class="text-sm font-medium text-gray-900 dark:text-white">
                                    After creating the transaction,  <span class="text-blue-600 hover:underline dark:text-blue-500">remember to pay attention to the response at the bottom</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </section>

              </div>
              <p class="text-right p-4 text-base font-medium font-[Montserrat] text-[#FFF]">Latest Sync : <span class="text-red-900">{{latestSync}}</span></p>
              <div class="w-full h-[80vh] ">
                  

<div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-gray-50 dark:bg-gray-900 rounded-md" v-if="transactions.length > 0">
  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
          <tr>
              <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                  User
              </th>
              <th scope="col" class="px-6 py-3">
                  Type
              </th>
              <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                  Status
              </th>
              <th scope="col" class="px-6 py-3">
                  Amount
              </th>
              <th scope="col" class="px-6 py-3">
                 Date
            </th>
          </tr>
      </thead>
      <tbody>
          <tr class="border-b border-gray-200 dark:border-gray-700" v-for="(value, key) in transactions.slice().reverse().slice(0, 10)" :key="key">
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  {{value.user}}
              </th>
              <td class="px-6 py-4">
                {{value.type}}
              </td>
              <td class="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                {{value.status}} - <span class="text-green-500">{{value.result}}</span>
              </td>
              <td class="px-6 py-4">
                <p class="line-through text-red-500" v-if="value.oldamount">{{value.oldamount}}&#x20BA;</p>
                {{value.amount}}&#x20BA; 
              </td>
              <td class="px-6 py-4">
                {{value.dateStr}}
              </td>
          </tr>

      </tbody>
  </table>
</div>

              </div>

          </div>
       </div>
       <div class="flex items-center flex-col relative justify-center p-4 h-fit mb-4 rounded bg-gray-50 dark:bg-gray-800">
        <h1 class=" m-4 top-0 left-0 font-bold text-2xl">Host to Host | <span class="text-red-500">Deposit</span> </h1>
        <div class="w-full h-fit p-4  bottom-0 flex gap-2">
            <div class="w-fit h-fit p-2 m-4 text-[#FFF] rounded-xl text-lg font-[Montserrat] font-medium border max-w-[50vw] bg-[#1E1E1E]">
    
              <div class="flex flex-col min-w-[35vw] h-fit bg-gray-900 text-gray-200 font-mono">
                <!-- Top bar -->
                <div class="flex items-center h-10 px-4 bg-gray-800">
                    <div class="h-3 w-3 mr-2 rounded-full bg-red-500"></div>
                    <div class="h-3 w-3 mr-2 rounded-full bg-yellow-500"></div>
                    <div class="h-3 w-3 rounded-full bg-green-500"></div>
                </div>
            
                <!-- CMD body -->
                <div class="flex-1 p-4">
                    <div class="mt-2">
                      <span class="text-green-500">&gt; Request Data:</span>
                      <div class="bg-gray-800 p-2 mt-1">
                          <p>    
                            <span class="text-green-500">curl</span> -X POST <span class="text-red-500">https://x7a5as4d1ggg1.store/AddToDeposit</span> \
                            <br>
                            -H "Content-Type: application/json" \
                            <br>
                            -d '{
                              <br>
                                &nbsp;"amount": <span class="text-green-500 truncate">"Amount"</span>,
                                <br>
                                &nbsp;"date": <span class="text-green-500">"Full Of Date"</span>
                                <br>
                                &nbsp;"hour": <span class="text-green-500">"Full Of Hour"</span>
                                <br>
                                &nbsp;"user": <span class="text-green-500">"Username"</span>
                                <br>
                                &nbsp;"uuid": <span class="text-green-500">"UUID for user"</span>
                                <br>
                                &nbsp;"username": <span class="text-green-500">"The real user name of user"</span>
                                <br>
                                &nbsp;"usersurname": <span class="text-green-500">"The real user surname of user"</span>
                                <br>
                                &nbsp;"bank": <span class="text-green-500">"Bank label"</span>
                                <br>
                                &nbsp;"iban": <span class="text-green-500">"iban value assigned to the user"</span>
                                <br>
                                &nbsp;"token": <span class="text-green-500">"the token we provided you"</span>
                                <br>
                            }'
                            </p>
                      </div>
                  </div>
                    <div class="mt-2">
                        <span class="text-green-500">&gt; Output:</span>
                        <div class="bg-gray-800 p-2 mt-1">
                            <p>   
                              {
                                <br>
                                  &nbsp;{status : true or false, message : "The message of response"}
                                <br>
                              }
                              </p>
                        </div>
                    </div>
    
                </div>
            </div>
            </div>
            <div class="p-4 text-base font-medium font-[Montserrat]">
              You can view all deposit and withdrawal transactions made by the user through our system.</div>
            
        </div>
       </div>
       <div class="grid grid-cols-1 gap-4 mb-4">
        <div class="flex p-[4vh] flex-col items-center rounded bg-green-500  gap-4 h-[200vh] dark:bg-gray-800">
          <div class="w-full h-fit"></div>
            <div class="w-full h-fit ">
              <section class="bg-gray-50 dark:bg-gray-900 rounded-md">
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
                    <div class="flex flex-col justify-center">
                        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Testing MID  <br>Host to Host <br><span class="text-red-500">Withdraw</span></h1>
                        <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Here you can use our API to create your own payment method and test it.</p>

                    </div>
                    <div>
                        <div class="w-full lg:max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow-xl dark:bg-gray-800">
                            <h2 class="text-2xl font-bold text-gray-900 dark:text-white">
                                Mospay Payment System
                            </h2>
                            <div class="mt-8 space-y-6" action="">
                                <div v-if="!selectediban" >
                                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Name</label>
                                    <input type="text" v-model="testmidname" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Richard" required />
                                </div>
                                <div v-if="!selectediban" >
                                  <label for="surname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Surname</label>
                                  <input type="text" v-model="testmidsurname" name="surname" id="surname" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Scott" required />
                              </div>
                                <div v-if="!selectediban" >
                                    <label for="amount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                                    <input type="number" v-model="testmidnumber" name="amount" id="amount" placeholder="100 TRY" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                </div>
                                <div v-if="!selectediban" >
                                  <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User - (Required for code integration) <span class="text-red-500">*</span></label>
                                  <input type="text" v-model="user" name="text" id="text" placeholder="hsnweix" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                              </div>
                              <div v-if="!selectediban" >
                                <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User UUID - (Required for code integration) <span class="text-red-500">*</span></label>
                                <input type="text" v-model="uuid" name="text" id="text" placeholder="#hsn123456" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                            </div>
                                <div v-if="!selectediban" >
                                  <label for="banklist" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bank</label>
                                  <select name="banklist" v-model="bank" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="cars">
                                    <option v-for="(value,key) in banklist"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :value="key" :key="key">{{value.name}}</option>
                                    
                                  </select>
                                </div>
                                <div v-if="!selectediban" >
                                  <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your IBAN <span class="text-red-500">*</span></label>
                                  <input type="text" v-model="useriban" name="text" id="text" placeholder="TR12 3456 78910 11412 1314 1516 17" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                              </div>
                                <div v-if="selectediban" >
                                  <label for="holder" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Account Holder</label>
                                  <input type="holder" readonly name="holder" id="holder" :placeholder="selectediban.holder" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                                </div>
                                <div v-if="selectediban" >
                                  <label for="iban" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">IBAN</label>
                                  <input type="iban" readonly name="iban" id="iban" :placeholder="selectediban.iban" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                                </div>


                                <button  class="w-full px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" v-if="!selectediban" @click="Withdraw(1)">Withdraw</button>
                                <div class="text-sm font-medium text-gray-900 dark:text-white">
                                  After creating the transaction,  <span class="text-blue-600 hover:underline dark:text-blue-500">remember to pay attention to the response at the bottom</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </section>

            </div>
            <p class="text-right p-4 text-base font-medium font-[Montserrat] text-[#FFF]">Latest Sync : <span class="text-red-900">{{latestSync}}</span></p>
            <div class="w-full h-[80vh] ">
                

<div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-gray-50 dark:bg-gray-900 rounded-md" v-if="transactions.length > 0">
<table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
        <tr>
            <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                User
            </th>
            <th scope="col" class="px-6 py-3">
                Type
            </th>
            <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                Status
            </th>
            <th scope="col" class="px-6 py-3">
                Amount
            </th>
            <th scope="col" class="px-6 py-3">
               Date
          </th>
        </tr>
    </thead>

    <tbody>
        <tr class="border-b border-gray-200 dark:border-gray-700" v-for="(value, key) in transactions.slice(0, 10)" :key="key">
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                {{value.user}}
            </th>
            <td class="px-6 py-4">
              {{value.type}}
            </td>
            <td class="px-6 py-4 bg-gray-50 dark:bg-gray-800">
              {{value.status}} - <span class="text-green-500">{{value.result}}</span>
            </td>
            <td class="px-6 py-4">
              <p class="line-through text-red-500" v-if="value.oldamount">{{value.oldamount}}&#x20BA;</p>
              {{value.amount}}&#x20BA; 
              
            </td>
            <td class="px-6 py-4">
              {{value.dateStr}}
            </td>
        </tr>

    </tbody>
</table>
</div>

            </div>

        </div>
     </div>
     <div class="flex items-center relative justify-center p-4 h-fit flex-col mb-4 rounded bg-gray-50 dark:bg-gray-800">
      <h1 class=" m-4 top-0 left-0 font-bold text-2xl">Host to Host | <span class="text-red-500">Withdraw</span> </h1>
      <div class="w-full h-fit p-5  bottom-0 flex gap-2">
          <div class="w-fit h-fit p-2 m-4 text-[#FFF] rounded-xl text-lg font-[Montserrat] font-medium border max-w-[50vw] bg-[#1E1E1E]">
  
            <div class="flex flex-col min-w-[35vw] h-fit bg-gray-900 text-gray-200 font-mono">
              <!-- Top bar -->
              <div class="flex items-center h-10 px-4 bg-gray-800">
                  <div class="h-3 w-3 mr-2 rounded-full bg-red-500"></div>
                  <div class="h-3 w-3 mr-2 rounded-full bg-yellow-500"></div>
                  <div class="h-3 w-3 rounded-full bg-green-500"></div>
              </div>
          
              <!-- CMD body -->
              <div class="flex-1 p-4">
                  <div class="mt-2">
                    <span class="text-green-500">&gt; Request Data:</span>
                    <div class="bg-gray-800 p-2 mt-1">
                        <p>    
                          <span class="text-green-500">curl</span> -X POST <span class="text-red-500">https://x7a5as4d1ggg1.store/AddToWithdraw</span> \
                          <br>
                          -H "Content-Type: application/json" \
                          <br>
                          -d '{
                            <br>
                            "data": {
                              <br>
                                &nbsp;&nbsp;"amount": <span class="text-green-500 truncate">"Amount"</span>,
                                <br>
                                &nbsp;&nbsp;"name": <span class="text-green-500">"Full name of user"</span>
                                <br>
                                &nbsp;&nbsp;"date": <span class="text-green-500">"Full of date"</span>
                                <br>
                                &nbsp;&nbsp;"hour": <span class="text-green-500">"Full hour"</span>
                                <br>
                                &nbsp;&nbsp;"uuid": <span class="text-green-500">"UUID for user"</span>
                                <br>
                                &nbsp;&nbsp;"user": <span class="text-green-500">"The username"</span>
                                <br>
                                &nbsp;&nbsp;"bank": <span class="text-green-500">"Bank label"</span>
                                <br>
                                &nbsp;&nbsp;"iban": <span class="text-green-500">"IBAN value"</span>
                                <br>
                                &nbsp;},
                            <br>
                              &nbsp;"token": <span class="text-green-500">"the token we provided you"</span>
                              <br>
                              
                          }'
                          </p>
                    </div>
                </div>
                  <div class="mt-2">
                      <span class="text-green-500">&gt; Output:</span>
                      <div class="bg-gray-800 p-2 mt-1">
                          <p>   
                            {
                              <br>
                                &nbsp;{status : true or false, message : "The message of response"}
                              <br>
                            }
                            </p>
                      </div>
                  </div>
  
              </div>
          </div>
          </div>
          <div class="p-4 text-base font-medium font-[Montserrat]">
            You can view all deposit and withdrawal transactions made by the user through our system.</div>
          
      </div>
     </div>
     <div class="flex items-center relative justify-center h-fit flex-col mb-4 rounded bg-gray-50 dark:bg-gray-800">
      <h1 class=" m-4 top-0 left-0 font-bold text-2xl">Get Bank List</h1>
      <div class="w-full h-fit p-4  bottom-0 flex gap-2">
          <div class="w-fit h-fit p-2 m-4 text-[#FFF] rounded-xl text-lg font-[Montserrat] font-medium border max-w-[50vw] bg-[#1E1E1E]">

            <div class="flex flex-col min-w-[35vw] h-fit bg-gray-900 text-gray-200 font-mono">
              <!-- Top bar -->
              <div class="flex items-center h-10 px-4 bg-gray-800">
                  <div class="h-3 w-3 mr-2 rounded-full bg-red-500"></div>
                  <div class="h-3 w-3 mr-2 rounded-full bg-yellow-500"></div>
                  <div class="h-3 w-3 rounded-full bg-green-500"></div>
              </div>
          
              <!-- CMD body -->
              <div class="flex-1 p-4">
                  <div class="mt-2">
                    <span class="text-green-500">&gt; Request Data:</span>
                    <div class="bg-gray-800 p-2 mt-1">
                        <p>    
                          <span class="text-green-500">curl</span> -X POST <span class="text-red-500">https://x7a5as4d1ggg1.store/GetCurrentBankList</span> \
                          <br>
                          -H "Content-Type: application/json" \
                          <br>
                          -d '{
 
                          }'
                          </p>
                    </div>
                </div>
                  <div class="mt-2">
                      <span class="text-green-500">&gt; Output:</span>
                      <div class="bg-gray-800 p-2 mt-1">
                          <p>   
                            table
                            </p>
                      </div>
                  </div>

              </div>
          </div>
          </div>
          <div class="p-4 text-base font-medium font-[Montserrat]">
            </div>
          
      </div>
 </div>
 <div class="flex items-center relative justify-center h-fit flex-col mb-4 rounded bg-gray-50 dark:bg-gray-800">
  <h1 class=" m-4 top-0 left-0 font-bold text-2xl">Get Free Iban</h1>
  <div class="w-full h-fit p-4  bottom-0 flex gap-2">
      <div class="w-fit h-fit p-2 m-4 text-[#FFF] rounded-xl text-lg font-[Montserrat] font-medium border max-w-[50vw] bg-[#1E1E1E]">

        <div class="flex flex-col min-w-[35vw] h-fit bg-gray-900 text-gray-200 font-mono">
          <!-- Top bar -->
          <div class="flex items-center h-10 px-4 bg-gray-800">
              <div class="h-3 w-3 mr-2 rounded-full bg-red-500"></div>
              <div class="h-3 w-3 mr-2 rounded-full bg-yellow-500"></div>
              <div class="h-3 w-3 rounded-full bg-green-500"></div>
          </div>
      
          <!-- CMD body -->
          <div class="flex-1 p-4">
              <div class="mt-2">
                <span class="text-green-500">&gt; Request Data:</span>
                <div class="bg-gray-800 p-2 mt-1">
                    <p>    
                      <span class="text-green-500">curl</span> -X POST <span class="text-red-500">https://x7a5as4d1ggg1.store/GetFreeIban</span> \
                      <br>
                      -H "Content-Type: application/json" \
                      <br>
                      -d '{
                        bank : "bank index"
                      }'
                      </p>
                </div>
            </div>
              <div class="mt-2">
                  <span class="text-green-500">&gt; Output:</span>
                  <div class="bg-gray-800 p-2 mt-1">
                      <p>   
                        {status = true or false, iban = table}
                        </p>
                  </div>
              </div>

          </div>
      </div>
      </div>
      <div class="p-4 text-base font-medium font-[Montserrat]">
        </div>
      
  </div>
</div>
       <div class="grid grid-cols-2 gap-4">

       </div>
    </div>
 </div>
</div>

 
    


</template>


<script setup>
//import { FwbSpinner  } from 'flowbite-vue'
import axios from 'axios'
</script>
<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      token: '',
      redirect_uri : '',
      user : '',
      bank : '',
      banklist : [],
      useriban : '',
      testmidsurname : '',
      testmidname : '',
      testmidnumber : '',
      uuid : '',
      selectediban : false,
      transactions : false,
      latestSync : ''
    }
  },
  mounted() {
    var self = this
    axios.post('https://x7a5as4d1ggg1.store/GetCurrentBankList', {
      })
      .then(response => {
        self.banklist = response.data.bankList
      })

      this.fetchTransactions();

// Her dakikada bir işlemi tekrar et
setInterval(() => {
  this.fetchTransactions();
}, 60000 * 5); // 60000 milisani
      
  },
  methods : {
  TestDeposit() {
    const self = this;
      axios.post('https://x7a5as4d1ggg1.store/deposit-control', {
        token: self.token,
        redirect_uri: self.redirect_uri,
        user: self.user,
        uuid: self.uuid
      })
      .then(response => {
        console.log(response)
        if (response.data.token) {
            const url = "https://x7a5as4d1ggg.com/deposit?token=" + response.data.token;
            window.location.href = url
        } else {
          alert('Error while checking deposit 3:' + response.data.error)
        }
      })
      .catch(error => {
        alert('Error while checking deposit 2 :', error);
      });
  },
  TestWithdraw() {
    const self = this;
      axios.post('https://x7a5as4d1ggg1.store/withdraw-control', {
        token: self.token,
        redirect_uri: self.redirect_uri,
        user: self.user,
        uuid: self.uuid
      })
      .then(response => {
        console.log(response)
        if (response.data.token) {
            const url = "https://x7a5as4d1ggg.com/withdraw?token=" + response.data.token;
            window.location.href = url
        } else {
          alert('Error while checking deposit 3:' + response.data.error)
        }
      })
      .catch(error => {
        alert('Error while checking deposit 2 :', error);
      });
  },
    fetchTransactions() {
      const self = this;
      axios.post('https://x7a5as4d1ggg1.store/GetAllTransactionFromToken', {
        token: localStorage.getItem('token'),
      })
      .then(response => {
        self.transactions = response.data.transactions;
        // En son senkronizasyon zamanını güncelle
        self.updateLatestSync();
      })
      .catch(error => {
        console.error('Error fetching transactions:', error);
      });
    },
    updateLatestSync() {
      const now = new Date();
      const formattedDate = `${this.pad(now.getDate())}/${this.pad(now.getMonth() + 1)}/${now.getFullYear()} ${this.pad(now.getHours())}:${this.pad(now.getMinutes())}`;
      this.latestSync = formattedDate;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    Withdraw() {
                const options = {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                        timeZone: 'Europe/Istanbul', // İstanbul zaman dilimi
                    };
                    const self = this;
                const date = new Date();
                const istanbul = date.toLocaleString('tr-TR', options);
                const dateStr = istanbul.split(' ')[0] 
                const hour = istanbul.split(' ')[1];
                    const data = {
                        amount : this.testmidnumber,
                        name : this.testmidname + " " + this.testmidsurname,
                        date : dateStr,
                        hour : hour,
                        bank : this.bank,
                        iban : this.useriban,
                        user : this.user,
                        uuid : this.uuid,
             
                    }
                    axios.post('https://x7a5as4d1ggg1.store/AddToWithdraw', {
                        token : localStorage.getItem('token'),
                        data : data
                    }).then(response => {

                      if (response.data.status == false) {
                          alert('An error occurred while creating the transaction')
                      } else {

                        alert('Transaction finished successfully')
                        self.fetchTransactions();
                          //this.$store.state.ibanBucket = response.data.ibanList
                      
                          //this.access = true
                      }
                      })
                      .catch(error => {
                      console.warn(error)
                  });
                    
    },
    CheckStep(step) {
      if (step == 1) {
        if (this.testmidname == '' || this.testmidsurname == '' || this.testmidnumber == '') {
          alert('Please fill in the required fields')
        } else {
          console.log("480")
          axios.post('https://x7a5as4d1ggg1.store/GetFreeIban', {
            bank : this.bank,
          })
          .then(response => {
            if (response.data.status) {
              
              this.selectediban = response.data.iban
            } else {
              alert('An error occurred while creating the transaction')
            
            }
          })
        }
      } else if (step == 2) {
        const options = {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                        timeZone: 'Europe/Istanbul', // İstanbul zaman dilimi
                    };
                const date = new Date();
                const istanbul = date.toLocaleString('tr-TR', options);
                const dateStr = istanbul.split(' ')[0] 
                const hour = istanbul.split(' ')[1];
        axios.post('https://x7a5as4d1ggg1.store/AddToDeposit', {
                        amount : this.testmidnumber,
                        companyaccount : this.selectediban.iban,
                        date : dateStr,
                        hour : hour,
                        user : this.user,
                        uuid : this.uuid,
                        username : this.testmidname,
                        usersurname : this.testmidsurname,
                        bank : this.selectediban.label,
                        iban : this.selectediban.iban,
                        token : localStorage.getItem('token')
                    })
                    .then(response => {

                        if (response.data.status == false) {
                            alert('An error occurred while creating the transaction')
                        } else {
                            
                          alert('Transaction finished successfully')
                          this.selectediban = false;
                          this.fetchTransactions();
                            //this.$store.state.ibanBucket = response.data.ibanList

                            //this.access = true
                        }
                    })
                    .catch(error => {
                        console.warn(error)
                    });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
