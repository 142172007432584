import { createApp } from 'vue'
import App from './App.vue'
import '../node_modules/flowbite-vue/dist/index.css'


import { createWebHistory, createRouter } from 'vue-router'

import DocInfo from './components/DocInfo.vue'
import Auth from './components/Auth.vue'
const routes = [
    { path : '/auth', component : Auth },
    { path: '/docinfo/:id', component: DocInfo},
    { path: '/', redirect: '/auth' },
  ]

const router = createRouter({
  history: createWebHistory(),
  routes,
})


const app = createApp(App)

app.use(router)
app.mount('#app')
